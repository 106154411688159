.dm-window {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dm-window > div:nth-child(2) {
  overflow-y: auto;
  padding: 0 10px 10px 10px;
  flex-grow: 1;
}

.dm-window > div:nth-child(3) {
  display: flex;
  align-items: center;
  background-color: var(--bg-color);
  gap: 10px;
  padding: 5px 10px;
}
